import React, { useState, useEffect } from 'react';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { get } from "lodash";
import { 
    Stack,
    Avatar,
	Tooltip,
    Box,
    IconButton
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import LanguageIcon from '@mui/icons-material/Language';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FaFacebookMessenger } from "react-icons/fa";
import { WEB_WIDGET_BUTTON_LOGO_SVG } from "./../../constants";
import useSettingsSelector from "./../../store/selectors/settings";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

const ChannelButtons = (props) => {

    const { botId, tenantId, lang } = props;
    const [isLoading, setIsLoading] = useState(true);
	const [widget, setWidget] = useState(null);
    const [channels, setChannels] = useState([])
    const [open, setOpen] = useState(false);

    const { WEB_WIDGET_BUTTON } = useSettingsSelector();

    const toggleButtons = () => {
        if(open){
            hideWebWidget()
        }
        setOpen(!open);
    };

    const toggleWebWidget = () => {
        if(open){
            hideWebWidget()
        }else{
            showWebWidget()
        }
        setOpen(!open);
    }
    const showWebWidget = () => {
        window.parent.postMessage({ showWidget: true },"*");
    };
    const hideWebWidget = () => {
        window.parent.postMessage({ hideWidget: true },"*");
    };
    const redirectToMessanger = (channel) => {
        const url = `${channel.prefix}${channel.name}`
        // Open the Telegram bot in a new tab
        window.open(url, "_blank");
    };
    const handleChannelClick = (origin, channel) => {
        switch (origin) {
            case 'Web':
                setOpen(true);
                showWebWidget()
                break;
            case 'Telegram':
                toggleButtons();
                redirectToTelegram(channel);
                break;
            case 'Whatsapp':
                toggleButtons();
                redirectToMessanger(channel);
                break;
            case 'Messenger':
                toggleButtons();
                redirectToMessanger(channel);
                break;
            default:
                console.warn(`Unrecognised channel origin ${origin}.`);
        }

    }
    
    const fetchWidget = async (botId, tenantId, lang) => {
        try {
            // Show loading state before fetching
            setIsLoading(true);
            // Fetch widget data
            const response = await axiosInstance.get(`/widgets/${botId}`, {
                params: { tenantId, lang },
            });
            // Validate response and update state
            if (response.status === 200) {
                const widgetData = get(response, "data.data", null);
                if (widgetData) {
                    setWidget(widgetData);
                } else {
                    console.warn("No widget data found in response.");
                }
            } else {
                console.error(`Failed to fetch widget. Status code: ${response.status}`);
            }
        } catch (error) {
            // Log and handle errors
            console.error("Error fetching widget:", error);
        } finally {
            // Ensure loading state is cleared
            setIsLoading(false);
        }
    };
    
    const getOriginSpecs = (origin) => {
        let avatar = "";
        let color = "";
    
        switch (origin) {
            case 'Web':
                avatar = <LanguageIcon sx={{ fontSize: '1.35em' }}/>
                color = "#6257FF"
                  break;
            case 'Telegram':
                avatar = <TelegramIcon sx={{ fontSize: '1.35em' }}/>
                color = "#0088cc"
                break;
            case 'Whatsapp':
                avatar = <WhatsAppIcon sx={{ fontSize: '1.35em' }}/>
                color = "#25d366"
                break;
            case 'Messenger':
                avatar = <FaFacebookMessenger sx={{ fontSize: '1.35em' }}/>
                color = "#0084FF"
                break;
            default:
              console.warn(`Unrecognized origin ${origin} for contact avatar`);
              avatar = <LanguageIcon />;
              color = "#6257FF"
        }
    
        return {avatar, color};
    }
    const Channel = ({channel}) => {
        if(channel){
            const {color, avatar} = getOriginSpecs(channel.origin);
            return (
                <Tooltip title={channel.origin} arrow placement="left">
                    <Avatar
                        sx={{
                            backgroundColor: color,
                            width: 60,
                            height: 60,
                            objectFit: 'cover',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 25,
                            cursor: "pointer"
                        }}
                        onClick={()=>{handleChannelClick(channel.origin, channel)}}
                        >
                        {avatar}
                    </Avatar>
                </Tooltip>
            )
        }else{
            return <></>
        }
        
    }
    
    useEffect(() => {
		if (tenantId && botId && lang) {
			fetchWidget(botId, tenantId, lang);
		}
	}, [tenantId, botId, lang]);
    
    useEffect(()=>{
        if(widget && Array.isArray(widget.channels)){
            setChannels(widget.channels)
        }
    }, [widget]);

    if(isLoading){
        return <></>
    }else{
        return (
            <Box sx={{ position: 'fixed', bottom: 20, right: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Stack direction="column" spacing={2}>
                    {open && (
                        <>
                            {Array.isArray(channels) && channels.length > 0 && channels.map((channel, idx) => {
                                return (
                                    <Box key={`channel-${idx}`}>
                                        <Channel 
                                            channel={channel}
                                        />
                                    </Box>
                                )
                            })}
                            <Box>
                                <Channel 
                                    channel={{
                                        origin: "Web"
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    <IconButton 
                        sx={{
                            width: '60px',
                            height: '60px',
                            backgroundColor: WEB_WIDGET_BUTTON.BACKGROUND_COLOR,
                            color: WEB_WIDGET_BUTTON.FONT_COLOR,
                            '&:hover': {
                                backgroundColor: WEB_WIDGET_BUTTON.BACKGROUND_COLOR,
                                color: WEB_WIDGET_BUTTON.FONT_COLOR,
                            },
                            animation: open ? 'rotate 0.3s ease-out forwards' : 'none', 
                            '@keyframes rotate': {
                                '0%': { transform: 'rotate(0deg)' },
                                '100%': { transform: 'rotate(90deg)' },
                            }
                        }}
                        onClick={Array.isArray(channels) && channels.length > 0 ? toggleButtons : toggleWebWidget}
                    >
                        {open ? <CloseIcon sx={{ fontSize: '1.35em', color: 'white' }} /> : WEB_WIDGET_BUTTON_LOGO_SVG}
                    </IconButton>
                </Stack>
            </Box>
          );
    }
};

export default ChannelButtons;