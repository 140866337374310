import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material"
const ConversationsNavigation = ({ meta }) => {
    const navigate = useNavigate();
     // Fetch localized text for "Conversations"
    const convLocale = meta?.locale ? meta.locale.find(el => el.field === "conversations") : null

    // Navigate to the Conversations page
    const handleNavigate = () => {
        navigate("/private/conversations");
    };

    return (
        <Box>
            {/* Navigation list for conversations */}
            <List className="basic-navigation-list">
                <Stack>
                    {/* Button to navigate to the Conversations page */}
                    <ListItemButton onClick={handleNavigate}>
                        <ListItemText>
                            <Typography variant="body1">
                                {convLocale?.name || "Conversations"}
                            </Typography>
                        </ListItemText>
                        <KeyboardArrowRightIcon />
                    </ListItemButton>
                </Stack>
            </List>
        </Box>
    );
};

export default ConversationsNavigation;