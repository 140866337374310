import { createContext, useState, useMemo } from "react";
const Context = createContext();

const ContextProvider = (props) => {
	const {
		children
	} = props;

	const [widget, setWidget] = useState(null);
	const [agents, setAgents] = useState([]);
	const [conversations, setConversations] = useState([])
	const [page, setPage] = useState({
		location: "home",
		props: {}
	});

	const value = useMemo(()=> ({
		widget,
		page,
		agents,
		conversations,
		setPage,
		setWidget,
		setAgents,
		setConversations
	}), [widget, agents, page, conversations]);
	return (
		<Context.Provider value={value}>
			{children}
		</Context.Provider>
	);
};

export {
	Context,
	ContextProvider
};