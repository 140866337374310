import React, { useState, useEffect, useRef, useContext } from "react";
import getBrowserFingerprint from "get-browser-fingerprint";
import axios from "axios";
import { get } from "lodash";
import ProgressBar from "../components/ProgressBar";
import UnVerifiedPage from "../pages/Unverified";
import SocketClient from "../socket-client";
import { Context } from "../store/auth";

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1",
});

// Generate a unique browser fingerprint to identify the user
const uuid = getBrowserFingerprint();

const ProtectedRoute = ({ botId, tenantId, host, lang, children }) => {
	const { widget, setWidget } = useContext(Context);
	// State variables
	const [isLoading, setIsLoading] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [initializedSocket, setInitializedSocket] = useState(false);
	// Reference for the socket client
	const socketRef = useRef(null);

	// Fetch widget data from the API using botId, tenantId, and lang
	// Updates the widget state and verifies the host domain
	const fetchWidget = async (botId, tenantId, lang) => {
		try {
			const response = await axiosInstance.get(`/widgets/${botId}?tenantId=${tenantId}&lang=${lang}`);
			if (response.status === 200) {
				const _widget = get(response, "data.data", null);
				if (_widget) {
					setWidget(_widget);
					validateDomain(_widget.domains); // Verify the host domain
				}
			}
		} catch (error) {
			console.error("Error fetching widget data:", error);
		} finally {
			setIsLoading(false);
		}
	};

	// Validates if the current host domain is allowed for the widget
	const validateDomain = (domains = []) => {
		const isHostVerified = domains.some(({ domain }) => domain.includes(host));
		setIsVerified(isHostVerified);
	};

	// Initializes the socket client for real-time communication
	const initializeSocket = () => {
		if (uuid && tenantId && botId && !initializedSocket) {
			socketRef.current = SocketClient.getInstance(tenantId, uuid, botId);
			setInitializedSocket(true);
		}
	};

	// Effect to fetch widget data when required props (tenantId, botId, lang) change
	useEffect(() => {
		if (tenantId && botId && lang) {
			fetchWidget(botId, tenantId, lang);
		}
	}, [tenantId, botId, lang]);

	// Effect to initialize the socket client when UUID, tenantId, or botId changes
	useEffect(() => {
		initializeSocket();
	}, [uuid, tenantId, botId]);

	// Render a loading spinner while widget data is being fetched
	if (isLoading) {
		return <ProgressBar />;
	}

	// If required data (widget or UUID) is missing, render nothing
	if (!widget || !uuid) {
		return null;
	}

	// Render the children components if the domain is verified
	// Otherwise, show the UnVerifiedPage component
	return isVerified ? (
		React.cloneElement(children, { widget, uuid })
	) : (
		<UnVerifiedPage />
	);
};

export default ProtectedRoute;