import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./../pages/Layout";
import Articles from "./../pages/Articles";
import Conversations from "./../pages/Conversations";
import Chat from "./../pages/Chat"


const PrivateRoutes = (props) => {
	return (
		<Routes>
			<Route path="/home" element={<Layout {...props} />}></Route>
			<Route path="/articles" element={<Articles {...props} />}></Route>
			<Route path="/conversations" element={<Conversations {...props} />}></Route>
			<Route path="/chat" element={<Chat {...props} />}></Route>
			<Route
				path="*"
				element={
					<div>
						<h2>404 Page not found</h2>
					</div>
				}
			/>
		</Routes>
	);
};

export default PrivateRoutes;