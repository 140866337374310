import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./private.routes";
import PublicRoutes from "./public.routes";
import ProtectedRoute from "./protectedRoute";

const MainRoutes = (props) => {
	return (
		<Routes>
			<Route
				path="/public/*"
				element={
					<PublicRoutes {...props}/>
				}
			/>
			<Route
				path="/private/*"
				element={
					<ProtectedRoute {...props}>
						<PrivateRoutes {...props}/>
					</ProtectedRoute>
				}
			/>
		</Routes>
	);
};
export default MainRoutes;
