import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { get } from "lodash";
import {
	Typography,
	Stack,
	IconButton,
	Card,
	CardHeader,
	CardContent
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import eventEmitter from "../../components/EventEmitter"
import useSettingsSelector from "../../store/selectors/settings";
import Home from "../Home";
import Footer from "../../components/Footer";
import { Context } from "../../store/auth";
import notificationSound from "../../assets/sounds/notification.wav";

// Create an Axios instance with a base URL for API calls
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

// Main Layout component
const Layout = (props) => {
	// Destructure props passed to the component
	const { botId, tenantId, uuid, projectUUID } = props;
	// Extract global context and state from Context
	const { page, widget, conversations, setConversations, agents, setAgents } = useContext(Context);
	// Extract header settings using a custom selector
	const { HEADER } = useSettingsSelector();
	// Define constants for header styling and titles
	const HEADER_BACKGROUND = get(widget, "chat.header.backgroundColor", HEADER.BACKGROUND_COLOR);
	const HEADER_TEXT_COLOR = get(widget, "chat.header.titleColor", HEADER.FONT_COLOR);
	const TITLE = get(widget, "chat.header.title", "");
	// State variables for FAQs, company information, and contact details
	const pageRef = useRef(page);
	const agentsRef = useRef(null);
	// State variables for FAQs, company information, agents and contact details
	const [faqs, setFaqs] = useState([]);
	const [company, setCompany] = useState(null);
	const [agentsOnline, setAgentsOnline] = useState([]);
	const [contact, setContact] = useState(null);

	// Function to hide the widget by sending a message to the parent window
	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true }, "*");
	};

	// Fetch and sanitize all conversations for the current user and bot
	const fetchConversations = async (uuid, botId, tenantId, projectUUID) => {
		try {
			const response = await axiosInstance.get(`/conversations/all/${uuid}_${botId}?botId=${botId}&uuid=${projectUUID}&tenantId=${tenantId}`);
			if (response.status === 200) {
				const data = get(response, "data.data", []);
				if (Array.isArray(data)) {
					// Sanitize and update conversations
					const sanitizedConversations = sanitizeConversations(data);
					setConversations(sanitizedConversations);
				} else {
					// Fallback to an empty conversations array
					setEmptyConversations();
				}
			}
		} catch (error) {
			console.error("@@ Error: fetchConversations @@", error);
			setEmptyConversations();
		}
	};

	// Utility function to sanitize conversations by processing their records
	// Ensures that sensitive or unnecessary data in conversation records is removed
	const sanitizeConversations = (conversations) => {
		// Map through each conversation in the array
		return conversations.map((conversation) => {
			// Sanitize the records for each conversation
			const sanitizedRecords = sanitizeRecords(conversation.records);
			// Return a new conversation object with sanitized records
			return { ...conversation, records: sanitizedRecords };
		});
	};

	// Helper function to sanitize individual records in a conversation
	// Removes specific types of records that are not needed for further processing
	const sanitizeRecords = (records) => {
		// Map through each record in the array
		return records.map((record) => {
			// If the record is of a specific type (e.g., contact_data_collector, data_collector, or ticket), remove the reply field
			if (
				record.reply?.type === "contact_data_collector" ||
				record.reply?.type === "data_collector" ||
				record.reply?.type === "ticket"
			) {
				record.reply = null; // Clear the reply field
			}
			return record; // Return the sanitized record
		});
	};

	// Utility function to reset conversations to an empty placeholder state
	// Used when there are no conversations to display or an error occurs while fetching conversations
	const setEmptyConversations = () => {
		// Update the conversations state with a single placeholder conversation
		setConversations([
			{
				_id: null, // Set conversation ID to null to indicate it's a placeholder
				placeholder: true, // Mark this conversation as a placeholder
				records: [], // Empty records array
			},
		]);
	};

	// Fetch contact details for the current user and bot
	const fetchContact = async (uuid, botId, tenantId, projectUUID) => {
		try {
			const response = await axiosInstance.get(`/contacts/${uuid}_${botId}?botId=${botId}&uuid=${projectUUID}&tenantId=${tenantId}`);
			if (response.status === 200) {
				const data = get(response, "data.data", null);
				if (data) {
					setContact(data);
					return;
				}
			}
		} catch (error) {
			console.error("@@ Error: fetchContact @@", error);
		}
	};

	// Handles the "agents_update" event to update the list of agents
	const handleAgents = (message) => {
		try {
			const { agents } = message; // Extract the agents array from the incoming message
			if (Array.isArray(agents)) {
				// Filter agents that have both a name and a profile URL
				const allAgents = agents.filter(el => el.name && el.profileURL);
				// Check if the new list of agents differs from the current list (to avoid unnecessary updates)
				if (!_.isEqual(agentsRef.current, allAgents)) {
					// Update the list of online agents (agents with presence === 1 and associated with the current project)
					setAgentsOnline(
						agents.filter(
							el => el.name && el.profileURL && el.presence === 1 &&
								Array.isArray(el.projects) && el.projects.includes(projectUUID)
						)
					);
					// Update the global list of agents
					setAgents(agents.filter(el => el.name && el.profileURL));
					// Update the reference to keep track of the current agent list
					agentsRef.current = agents.filter(el => el.name && el.profileURL);
				}
			}
		} catch (error) {
			console.error("@@ Error: handleAgents @@", error); // Log any errors that occur
		}
	};

	// Updates conversation records dynamically when new records are received
	const updateConversationRecords = (record) => {
		setConversations((prevConversations) =>
			prevConversations.map((conversation) => {
				// Create a copy of the current conversation
				const updatedConversation = { ...conversation };
				// Assign the record"s conversation ID to the placeholder conversation if it doesn't already have one
				if (updatedConversation.placeholder && !updatedConversation._id) {
					updatedConversation._id = record.conversationId;
				}
				// If the conversation ID matches, update the conversation's records
				if (updatedConversation._id === record.conversationId) {
					updatedConversation.records = mergeConversationRecords(updatedConversation.records, record);
				}
				// Return the updated conversation
				return updatedConversation;
			})
		);
	};

	// Merges a new record into an existing list of conversation records
	const mergeConversationRecords = (records, newRecord) => {
		let recordExists = false; // Flag to check if the record already exists in the list
		// Map through the existing records and either update or retain them
		const updatedRecords = records.map((record) => {
			// Check if the current record matches the new record by ID
			if (record._id === newRecord._id) {
				recordExists = true; // Mark the record as existing
				return newRecord; // Replace the existing record with the new one
			}
			return record; // Retain the existing record if no match is found
		});
		// If the new record doesn't already exist, add it to the list
		if (!recordExists) {
			updatedRecords.push({ ...newRecord });
		}
		return updatedRecords; // Return the updated list of records
	};
	

	// Function to play the notification sound
	const playNotificationSound = () => {
		const audio = new Audio(notificationSound); // Use the imported sound file
		audio.play().catch((error) => {
			console.error("Error playing notification sound:", error); // Log any playback errors
		});
	};

	// Event handler for updating conversation records dynamically
	const handleUpdateRecord = (message) => {
		try {
			const { contactId, record } = message;
			if (contactId == `${uuid}_${botId}`) {
				playNotificationSound();
				updateConversationRecords(record)
			}
		} catch (error) {
			console.error("@@ Error: handleUpdateRecord @@", error);
		}
	}

	// Handles the "contact_update" event to update the contact details dynamically
	const handleContactUpdate = (message) => {
		const { contact } = message; // Extract the contact object from the message
		setContact(contact); // Update the contact state with the new contact information
	};

	// Handles the "conversation_assignee" event to update assigned agents for a conversation
	const handleConvAssignee = (message) => {
		const { assignees } = message; // Extract the assignees array from the message
		const assignedAgents = []; // Initialize an empty array for storing matched agents
		// Ensure assignees is an array before processing
		if (Array.isArray(assignees)) {
			// Iterate through each assignee ID
			assignees.forEach((assignee) => {
				// Find the corresponding agent from the current list of agents
				const agent = agentsRef.current.find(el => el.agentIDaasId === assignee);
				if (agent) {
					assignedAgents.push(agent); // Add the agent to the assigned agents list
				}
			});
		}
		// Fetch updated conversations to reflect the changes in assignees
		fetchConversations(uuid, botId, tenantId);
	};

	// Syncs the page state with a reference to ensure it always holds the latest value
	useEffect(() => {
		pageRef.current = page; // Update the pageRef to point to the latest page state
	}, [page]); // Re-run the effect whenever the page state changes

	// Effect to fetch conversations and contact details when the component mounts or dependencies change
	useEffect(() => {
		if (uuid && widget && tenantId && botId) {
			fetchContact(uuid, botId, tenantId, projectUUID)
			fetchConversations(uuid, botId, tenantId, projectUUID);
		}
	}, [uuid, widget, tenantId, botId, projectUUID]);

	// Effect to update FAQs and company details when the widget state changes
	useEffect(() => {
		if (widget) {
			setFaqs(widget.faqs); // Set FAQs from widget state
			setCompany(get(widget, "chat.header.company", null)); // Set company name from widget state
		}
	}, [widget]);

// Attach event listeners for assistant typing, record and agents updates, and assignee changes
	useEffect(() => {
		eventEmitter.on("agents_update", handleAgents);
		eventEmitter.on("record_update", handleUpdateRecord);
		eventEmitter.on("contact_update", handleContactUpdate);
		eventEmitter.on("conversation_assignee", handleConvAssignee);
		// Cleanup listener on unmount
		return () => {
			eventEmitter.off("agents_update", handleAgents);
			eventEmitter.off("record_update", handleUpdateRecord);
			eventEmitter.off("contact_update", handleContactUpdate);
			eventEmitter.off("conversation_assignee", handleConvAssignee);
		};
	}, []);

	const HomeHeader = () => (
		<CardHeader
			className="card-header"
			sx={{
				"--header-background-color": HEADER_BACKGROUND,
			}}
			action={
				<IconButton aria-label="close" onClick={hideWidget}>
					<IoMdClose />
				</IconButton>
			}
			title={
				<Stack direction="column" className="card-header-title" sx={{
					"--header-text-color": HEADER_TEXT_COLOR,
				}} >
					{company && (
						<Typography variant="h5">{company}</Typography>
					)}
					{TITLE && (
						<Typography variant="h4">
							{TITLE}
						</Typography>
					)}
				</Stack>
			}
		/>
	);

	// Render the component
	return (
		<>
			<Card className="widget-root">
				<HomeHeader />
				<CardContent className={page.location === "home" ? "home-content" : "chat-content"}>
					{Array.isArray(conversations) && conversations.length > 0 && (
						<>
							<Home
								{...props}
								faqs={faqs}
								contact={contact}
								agents={agents}
								agentsOnline={agentsOnline}
								conversations={conversations}
							/>
						</>
					)}
				</CardContent>
				<Footer />
			</Card>
		</>
	);
};

export default Layout;