import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, List, ListItemButton, ListItemText, CardHeader, Typography } from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Context } from "../../store/auth";
import Footer from "../../components/Footer";

// Articles component to display a list of FAQs and navigate to chat with selected questions
const Articles = () => {
    // Extract `widget` and `conversations` from the Context
    const { widget, conversations } = useContext(Context);
    // Find the placeholder conversation, used as the default conversation
    const conversation = conversations?.find((conv) => conv.placeholder === true) || null;
    // Extract FAQs (articles) from the widget metadata
    const articles = widget?.faqs || [];
    const navigate = useNavigate();

    // Navigate back to the home page
    const handleBack = () => {
        navigate("/private/home");
    };

    // Hide the widget by sending a message to the parent window
    const hideWidget = () => {
        window.parent.postMessage({ hideWidget: true }, "*");
    };

    // Navigate to the Chat page with the selected article
    const handleNavigateToChat = (article) => {
        navigate("/private/chat", {
            state: {
                conversationId: conversation ? conversation._id : null,
                article,
                conversations
            },
        });
    };

    // Header component for the Articles page
    const Header = () => (
        <CardHeader className="basic-header"
            avatar={
                <IconButton aria-label="back" onClick={handleBack}>
                    <IoChevronBack />
                </IconButton>
            }
            action={
                <IconButton aria-label="close" onClick={hideWidget}>
                    <IoMdClose />
                </IconButton>
            }
            title={
                <Typography variant="body1" className="basic-header-title">
                    FAQs
                </Typography>
            }
        />
    );

    // Render the Articles component layout
    return (
        <Box className="articles-container">
            <Box className="articles-body">
                <Header />
                <List className="articles-list">
                    {Array.isArray(articles) &&
                        articles.map((option, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() =>
                                    handleNavigateToChat(option.question)
                                }
                            >
                                <ListItemText>{option.question}</ListItemText>
                                <KeyboardArrowRightIcon />
                            </ListItemButton>
                        ))}
                </List>
            </Box>
            <Footer />
        </Box>
    );
};

export default Articles;
