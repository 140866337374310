import React from "react";
import Message from "./TextMessage";
import { Button, Stack, Box, IconButton } from "@mui/material";

const MessageAndButtons = (props) => {
	const {content, handleRequest, conversationId} = props;

	const TextButton = ({el}) => {
		return (
			<Button 
				variant="outlined"
				sx={{
					borderColor: "lightgrey", 
					color: "black",
					borderRadius: "12px",
					width: "fit-content",
					justifyContent: "flex-end",
				}}
				onClick={()=>{handleRequest({
					conversationId: conversationId,
					callback_query: {
						data: el.callback_data,
						flow: content.flow,
						metadata: content.metadata || {}
					}
				});}}
			>{el.text}</Button>
		)
	}
	const EmojiButton = ({el}) => {
		return (
			<IconButton
				onClick={()=>{handleRequest({
					conversationId: conversationId,
					callback_query: {
						data: el.callback_data,
						flow: content.flow,
						metadata: content.metadata || {}
					}
				});}}
			>
				{el.callback_data === "Negative" && <span role="img" aria-label="heart">😡</span>}
				{el.callback_data === "Neutral" && <span role="img" aria-label="heart">😐</span>}
				{el.callback_data === "Positive" && <span role="img" aria-label="heart">😄</span>}
			</IconButton>
		)
	}
	return (
		<>
			<Message {...props}/>
			<Stack direction={content.flow === "rate_conversation" ? "row" : "column"} spacing={1} 
				sx={{width: "100%", margin: "10px 0", justifyContent: "end", alignItems: "end"}}>
				{content.buttons.map((el, idx) => {
					return (
						<Box key={`${el.text}${idx}`} >{content.flow === "rate_conversation" ? <EmojiButton el={el}/> : <TextButton el={el}/>}</Box>
					);
				})}
			</Stack>
		</>
	);
};

export default MessageAndButtons;