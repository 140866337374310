import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material";

const ArticlesNavigation = ({ meta }) => {
    const navigate = useNavigate();
    const searchLocale = meta?.locale ? meta.locale.find(el => el.field === "search") : null;

  // Navigate to the articles page
    const handleNavigate = () => {
        navigate("/private/articles");
    };

    return (
        <Box>
        {/* Navigation List */}
            <List className="basic-navigation-list">
                <Stack>
                    <ListItemButton onClick={handleNavigate}>
                        <ListItemText>
                            <Typography variant="body1">
                                {searchLocale && searchLocale.name ? searchLocale.name : "Search"} FAQs
                            </Typography>
                        </ListItemText>
                        <KeyboardArrowRightIcon />
                    </ListItemButton>
                </Stack>
            </List>
        </Box>
    );
};

export default ArticlesNavigation;
