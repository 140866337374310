import React, { memo } from "react";
import { Stack } from "@mui/material";
import {
    RecentConversations,
    ChatNavigation,
    ConversationsNavigation,
    ArticlesNavigation,
} from "../../components/Navigation";

// Home component to render navigation and recent conversations
const Home = (props) => {
    // Destructure props to extract relevant data
    const { faqs, agents, agentsOnline, conversations, widget } = props
    // Find the placeholder conversation (used for new or inactive conversations)
    const placeholderConv = conversations?.find((conv) => conv.placeholder === true) || null;
    // Determine if FAQs, agents, and conversations exist
    const hasFaqs = Array.isArray(faqs) && faqs.length > 0; // Check if there are FAQs
    const hasAgents = Array.isArray(agents) && agents.length > 0; // Check if there are agents
    const hasConversations = Array.isArray(conversations) && conversations.filter((conv) => conv.placeholder !== true).length > 1; // Check if there are valid conversations


    // Render the ArticlesNavigation component if FAQs exist
    const renderArticlesNavigation = () => {
        if (hasFaqs) {
            return (
                <ArticlesNavigation
                    articles={faqs}
                    meta={widget.meta}
                    conversationId={placeholderConv?._id}
                />
            );
        }
        return null;
    };

    // Render the ConversationsNavigation component if agents and conversations exist
    const renderConversationsNavigation = () => {
        if (hasAgents && hasConversations) {
            return <ConversationsNavigation meta={widget.meta} />;
        }
        return null;
    };

    // Render the RecentConversations component if agents exist
    const renderRecentConversations = () => {
        if (hasAgents) {
            return (
                <RecentConversations
                    {...props}
                    meta={widget.meta}
                />
            );
        }
        return null;
    };

    // Render the ChatNavigation component
    const renderChatNavigation = () => {
        return (
            <ChatNavigation
                agents={agentsOnline}
                meta={widget.meta}
                conversation={placeholderConv}
            />
        );
    };

    // Render the Home component's layout
    return (
        <Stack direction="column" spacing={1} className="home-container">
            {renderArticlesNavigation()} {/* Render navigation for FAQs */}
            {renderConversationsNavigation()} {/* Render navigation for conversations */}
            {renderRecentConversations()} {/* Render recent conversations */}
            {renderChatNavigation()} {/* Render navigation for chat */}
        </Stack>
    );
};

export default memo(Home);
