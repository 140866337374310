import React, {useEffect, useState} from "react";
import { BiSend } from "react-icons/bi";
import { 
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@mui/material";

import TextMessage from "./TextMessage"

const DataCollector = (props) => {
	const {content, handleRequest, setHidden, conversationId} = props;

	const [innerAttrs, setInnerAttrs] = useState([]);
	const [currAttr, setCurrentAttr] = useState(null);
	const [processed, setProcessed] = useState(false);
	const [message, setMessage] = useState(null);

	const getNextAttr = (attrs) => {
		for(let i = 0; i < attrs.length; i++){
			if(!attrs[i].value) {
				return attrs[i]
			};
		}
		return null;
	};
	const handleSubmit = async () => {
		const _attrs = innerAttrs.map(attr => {
			if(attr.field === currAttr.field){
				attr.value = currAttr.value;
			}
			return attr;
		});
		setInnerAttrs(_attrs);
	};
	useEffect(()=>{
		if(content){
			const data = content.config.attrs.map(attr => {
				attr.value = attr?.value && attr.value !== "" ? attr.value : "";
				return attr;
			})
			if(content.config?.msg){
				setMessage(content.config.msg)
			}
			setInnerAttrs(data);
		}
	}, [content]);
	useEffect(()=>{
		if(Array.isArray(innerAttrs) && innerAttrs.length > 0){
			const nextAttr = getNextAttr(innerAttrs);
			if(nextAttr){
				setCurrentAttr(nextAttr);
			}else{
				setCurrentAttr(null);
				if(!processed){
					handleRequest({
						conversationId: conversationId,
						action_query: {
							actionId: content.actionId,
							flow: content.flow,
							callback: {
								data: {
									...content.config,
									intent: content.config.intent,
									attrs: innerAttrs,
								}
							}
						}
					});
					setHidden(true)
					setProcessed(true)
				}
			}
		}
	}, [innerAttrs]);
	return (
		<>
			{message && (
				<TextMessage 
					content={{
						message: message
					}}
				/>
			)}
			
			{currAttr && (
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					sx={{ margin: "10px auto"}}
				>
					<InputLabel
						htmlFor="prompt">
						{currAttr.name}
					</InputLabel>
					<OutlinedInput
						id="prompt"
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setCurrentAttr({...currAttr, value: e.target.value}); }}
						value={currAttr.value}
						autoFocus={true}
						multiline={currAttr.type === "textarea"}
						rows={currAttr.type === "textarea" ? 4 : 1}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									color="primary"
									disabled={currAttr.value.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						label="Ask me anything"
						sx={{ 
							backgroundColor: "#FFFFFF",
							borderRadius: "12px"
						}}
					/>
				</FormControl>
			)}
		</>
	);
};

export default DataCollector;