import React, { useState, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import AvatarStack from "../AvatarStack";
import { formatTimeAgo, trimString } from "../../utils/common";
import { useNavigate } from "react-router-dom";

const RecentConversations = ({ conversations, agents, meta }) => {
    // Locale for recent conversations title
    const recentConvLocale = meta?.locale
        ? meta.locale.find((el) => el.field === "recent_conversations")
        : null;

    const [recentConv, setRecentConv] = useState([]);
    const navigate = useNavigate();

    // Format the time string into a "time ago" format
    const getTimeAgo = (timeStr) => {
        try {
            return formatTimeAgo(timeStr);
        } catch (error) {
            console.error("@@ Error: getTimeAgo @@", error);
            return null;
        }
    };

    // Navigate to the chat screen with the selected conversation
    const handleNavigateToChat = (conversationId, assignedAgents) => {
        navigate("/private/chat", {
            state: {
                conversationId,
                assignedAgents,
                displayAgentInfo: true,
                origin: "home",
            },
        });
    };

    // Fetch and process recent conversations whenever conversations or agents change
    useEffect(() => {
        if (Array.isArray(conversations) && Array.isArray(agents)) {
            const latestConversation = conversations
                .filter((conv) => conv.placeholder !== true) // Exclude placeholder conversations
                .sort((a, b) => {
                    const lastUpdatedA = new Date(
                        a.records[a.records.length - 1]?.updatedAt || a.createdAt
                    );
                    const lastUpdatedB = new Date(
                        b.records[b.records.length - 1]?.updatedAt || b.createdAt
                    );
                    return lastUpdatedB - lastUpdatedA; // Sort by most recent update or creation date
                })
                .slice(0, 1); // Fetch only the most recent conversation

            // Map conversations to a processed structure
            const recentConversations = latestConversation.map((conv) => {
                const assignedAgents = [];
                const lastRecord = conv.records[conv.records.length - 1];
                conv.assignees.forEach((agentIDaasId) => {
                    const agent = agents.find((el) => el.agentIDaasId === agentIDaasId);
                    if (agent) {
                        assignedAgents.push(agent); // Collect assigned agents
                    }
                });
                return {
                    conversationId: conv._id,
                    assignees: conv.assignees,
                    agents: assignedAgents,
                    lastMsg: trimString(
                        lastRecord?.reply?.message || lastRecord?.query?.message,
                        34
                    ), // Trim the last message to 34 characters
                    time: getTimeAgo(lastRecord?.updatedAt || conv.createdAt), // Format the last update time
                };
            });

            setRecentConv(recentConversations);
        }
    }, [conversations, agents]);

    return (
        <>
            {Array.isArray(recentConv) && recentConv.length > 0 && (
                <Box>
                    <List className="basic-navigation-list">
                        {/* Title for recent conversations */}
                        <Typography className="recent-conversation-title" variant="body1">
                            {recentConvLocale?.name || "Recent conversation"}
                        </Typography>

                        {/* Render each recent conversation */}
                        {recentConv.map((conv, idx) => (
                            <Stack key={`recent-conversation-${idx}`}>
                                <ListItemButton
                                    onClick={() =>
                                        handleNavigateToChat(conv.conversationId, conv.agents)
                                    }
                                >
                                    <ListItemText>
                                        <Stack direction="row" spacing={1}>
                                            {/* Display agents */}
                                            <AvatarStack agents={conv.agents || []} />
                                            <Stack direction="column" spacing={0}>
                                                {/* Display last message */}
                                                <Typography variant="body1">
                                                    {conv.lastMsg}
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    className="recent-conversation-stack"
                                                >
                                                    {/* Display single agent's name if only one agent */}
                                                    {conv.agents.length === 1 && (
                                                        <Typography variant="body1">
                                                            {conv.agents[0]?.name}
                                                        </Typography>
                                                    )}
                                                    <Typography>•</Typography>
                                                    {/* Display last update time */}
                                                    <Typography variant="body1">
                                                        {conv.time && <> {conv.time}</>}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </ListItemText>
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </Stack>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};

export default RecentConversations;