import React from "react";
import {
	Avatar,
	Typography,
	Stack,
	Box
} from "@mui/material";

const AvatarStack = ({ agents, displayAgentInfo }) => {
	// Render up to 4 avatars, showing "+X" for additional agents
	const renderAvatars = () =>
		agents.slice(0, 4).map((agent, index) => {
			if (index === 3 && agents.length > 3) {
				return (
					<Box className="more-avatar" key={index}>
						+{agents.length - 3}
					</Box>
				);
			}
			return (
				<Avatar
					key={index}
					alt={agent.name || "Agent"}
					src={agent.profileURL || ""}
				>
					{agent.profileURL ? null : agent.name ? agent.name.charAt(0) : "A"}
				</Avatar>
			);
		});

	return (
		<>
			{/* Single agent: show avatar, name, and status */}
			{agents && agents.length === 1 && (
				<Stack direction="row" spacing={1}>
					<Avatar
						alt={agents[0].name || "Agent"}
						src={agents[0].profileURL || ""}
						className="styled-avatar"
					/>
					{displayAgentInfo && (
						<Stack direction="column" spacing={0}>
							<Typography variant="body1" className="agent-name">
								{agents[0].name}
							</Typography>
							<Typography variant="body2" className="agent-presence">
								{agents[0].presence === 1 ? "Online" : "Offline"}
							</Typography>
						</Stack>
					)}
				</Stack>
			)}

			{/* Multiple agents: show up to 4 avatars */}
			{agents && agents.length > 1 && (
				<Box className="avatars-container">{renderAvatars()}</Box>
			)}
		</>
	);
};

export default AvatarStack;