import React, { useState, useEffect, useRef, memo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { get } from "lodash";
import { IoMdSend } from "react-icons/io";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import {
	Box,
	FormControl,
	InputAdornment,
	OutlinedInput,
	IconButton,
	CardHeader
} from "@mui/material";
import History from "./history";
import Footer from "../../components/Footer";
import eventEmitter from "./../../components/EventEmitter";
import AvatarStack from "./../../components/AvatarStack";
import TypingIndicator from "../../components/TypingIndicator";
import { Context } from "../../store/auth";
import notificationSound from "../../assets/sounds/notification.wav";

// Create an Axios instance for API calls
const axiosInstance = axios.create({
	baseURL:
		process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1"
});

// Main Chat component
const Chat = (props) => {
	// Destructure props
	const { botId, uuid, lang, tenantId, projectUUID } = props;
	// Use global Context for shared state and functions
	const { widget, setPage, conversations, setConversations, contact, agents } = useContext(Context);
	// Extract state from location and reference for persistence
	const { state } = useLocation();
	const pageProps = state || {};
	const navigate = useNavigate();
	const pagePropsRef = useRef(pageProps);
	// References for managing agents and records
	const agentsRef = useRef(null);
	const [assignedAgents, setAssignedAgents] = useState([]);
	const [records, setRecords] = useState([]);
	const recordsRef = useRef(records);
	const [initialized, setInitialized] = useState(false);
	// Typing indicator and query state management
	const [assistantTyping, setAssistantTyping] = useState(false);
	const [isLastRecordCollector, setIsLastRecordCollector] = useState(false);
	const [query, setQuery] = useState("");
	const [lastQuery, setLastQuery] = useState("");
	// Extract locale-specific text from widget metadata
	const locales = get(widget, "meta.locale", []);
	const sendMsgLocale = locales.find((el) => el.field === "send_msg");

	// Function to update assigned agents 
	const updateAssignedAgents = (assignees) => {
		const updatedAgents = assignees
			.map((assigneeId) =>
				agentsRef.current.find((agent) => agent.agentIDaasId === assigneeId)
			)
			.filter(Boolean);
		setAssignedAgents(updatedAgents);
	};

	// Function to fetch assigned agents 
	const handleConvAssignee = (message) => {
		const { assignees, newConvId, prevConvId } = message;
		if (Array.isArray(assignees)) {
			updateAssignedAgents(assignees);
		}
		setConversations((prevConversations) => {
			const updatedConversations = prevConversations.map((conversation) => {
				if (conversation._id === prevConvId) {
					return {
						...conversation,
						_id: newConvId, // Update the ID to the new conversation ID
					};
				}
				return conversation;
			});
			return updatedConversations;
		});
		setPage((prevState) => {
			if (prevState.location === "chat" && prevState.props.conversationId === prevConvId) {
				const updatedPage = {
					location: "chat",
					props: {
						...prevState.props,
						conversationId: newConvId,
						assignedAgents: assignedAgents,
					},
				};
				pagePropsRef.current = updatedPage;
				return updatedPage;
			}
			return prevState;
		});
	};
	
	// Function to send a request to the backend
	const handleRequest = async (content) => {
		try {
			await axiosInstance.post(`/widgets/query`, {
				content,
				tenantId,
				botId,
				uuid: projectUUID,
				contactId: uuid,
				lang,
				href: window.location.hostname,
			});
		} catch (error) {
			console.error("Error in handleRequest:", error);
		}
	};

	// Function to handle user query submission
	const handleSubmit = () => {
		if (!query.trim()) return;

		handleRequest({
			conversationId: pagePropsRef.current?.conversationId,
			msg_query: { text: query },
		});
		setQuery(""); // Clear input after submission
	};

	// Initialize a session and fetch contact details if no records exist
	const initSessionAndGetContact = async (_records) => {
		if (_records.length === 0) {
			await handleRequest({
				conversationId: pagePropsRef.current?.conversationId,
				new_session: true
			});
		}
	};

	// Handle assistant typing indicator
	const handleAssistantTyping = (message) => {
		const { conversationId } = message;
		if (pagePropsRef.current?.conversationId === conversationId) {
			setAssistantTyping(true);
		}
	};

	// Function to play the notification sound
	const playNotificationSound = () => {
		const audio = new Audio(notificationSound); // Use the imported sound file
		audio.play().catch((error) => {
			console.error("Error playing notification sound:", error); // Log any playback errors
		});
	};

	// Function to handle record updates
	const handleRecordUpdate = (message) => {
		const { contactId, record } = message;
		// Validate if the record belongs to the current conversation
		if (contactId !== `${uuid}_${botId}`) {
			return;
		}
		setConversations((prevConversations) => {
			const updatedConversations = prevConversations.map((conversation) => {
				if (conversation._id === record.conversationId) {
					const updatedRecords = [...(conversation.records || [])];
					const recordIndex = updatedRecords.findIndex((r) => r._id === record._id);
	
					if (recordIndex >= 0) {
						updatedRecords[recordIndex] = { ...record, isIncoming: true };
					} else {
						updatedRecords.push({ ...record, isIncoming: true });
					}
					const lastRecordReply = record.reply;
					if (lastRecordReply) {
						setAssistantTyping(false); // Turn off typing indicator
						setIsLastRecordCollector(
							lastRecordReply.type === "data_collector" ||
							lastRecordReply.type === "contact_data_collector" ||
							lastRecordReply.type === "ticket"
						);
					}
	
					return { ...conversation, records: updatedRecords };
				}
				return conversation;
			});
			return updatedConversations;
		});
		// Play notification sound for specific record types
		if (record.reply?.agentIDaasId) {
			playNotificationSound();
		}
	};
	
	// Navigate back to the previous page
	const handleBack = () => {
		const previousPage = pagePropsRef.current?.origin || "home";
		if (previousPage === "conversations") {
			setPage({ location: "conversations", props: {} });
			navigate("/private/conversations");
		} else {
			setPage({ location: "home", props: {} });
			navigate("/private/home");
		}
	};

	// Function to hide the widget by sending a message to the parent window
	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true }, "*");
	};

	// Automatically trigger handleRequest if `article` exists
	useEffect(() => {
		if (pagePropsRef.current?.article) {
			handleRequest({
				conversationId: pagePropsRef.current?.conversationId,
				msg_query: { text: pagePropsRef.current?.article },
			});
		}
	}, [pageProps.article, pageProps.conversationId]);

	// Synchronizing the conversation state with the records displayed in the chat
	useEffect(() => {
		if (conversations && pagePropsRef) {
			const conversation = pagePropsRef.current?.conversationId
				? conversations.find((conv) => conv._id === pagePropsRef.current?.conversationId)
				: conversations.find((conv) => conv.placeholder === true);

			if (conversation) {
				const _records = conversation.records || [];
				setRecords(_records);
				if (conversation.placeholder === true && !initialized) {
					initSessionAndGetContact(_records);
					setInitialized(true);
				}
			} else {
				console.error(
					"No matching conversation found for conversation ID", pagePropsRef.current?.conversationId
				);
			}
		}
	}, [conversations, pagePropsRef]);
	
	// Sync agents with agentsRef
	useEffect(() => {
		if (agents) {
			agentsRef.current = agents;
			const conversation = conversations?.find(
				(conv) => conv._id === pagePropsRef.current?.conversationId,
			);
			if (conversation?.assignees) {
				updateAssignedAgents(conversation.assignees); // Update assigned agents
			}
		}
	}, [agents, conversations, pagePropsRef]);

	// Sync reords with recordsRef
	useEffect(() => {
		recordsRef.current = records;
	}, [records]);

	// Sync pageProps with pagePropsRef
	useEffect(() => {
		pagePropsRef.current = pageProps;
	}, [pageProps]);

	// Attach event listeners for assistant typing, record updates, and assignee changes
	useEffect(() => {
		eventEmitter.on("assistant_typing", handleAssistantTyping);
		eventEmitter.on("record_update", handleRecordUpdate);
		eventEmitter.on("conversation_assignee", handleConvAssignee);
		return () => {
			eventEmitter.off("assistant_typing", handleAssistantTyping);
			eventEmitter.off("record_update", handleRecordUpdate);
			eventEmitter.off("conversation_assignee", handleConvAssignee);
		};
	}, []);

	// Header component for the chat interface
	const Header = () => {
		return (
			<CardHeader className="basic-header"
				avatar={(
					<IconButton aria-label="back" onClick={handleBack}>
						<IoChevronBack />
					</IconButton>
				)}
				action={
					<IconButton aria-label="close" onClick={hideWidget}>
						<IoMdClose />
					</IconButton>
				}
				title={(
					<Box className="agents-info">
						<AvatarStack
							{...props}
							agents={assignedAgents || []}
							displayAgentInfo={true}
						/>
					</Box>
				)}
			/>
		)
	}

	// Render the Chat component
	return (
		<Box className="chat-container">
			<Header assignedAgents={pageProps.assignedAgents || []} />
			<History
				{...props}
				locales={locales}
				records={records}
				query={lastQuery}
				setLastQuery={setLastQuery}
				handleRequest={handleRequest}
				agents={agents}
				conversationId={pageProps.conversationId}
				contact={contact}
			/>
			{assistantTyping && (
				<TypingIndicator />
			)}
			<FormControl
				variant="outlined"
				fullWidth
				size="small"
				className="chat-input"
			>
				<OutlinedInput
					onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
					onChange={(e) => { setQuery(e.target.value); }}
					className="send-message-textfield"
					value={query}
					autoFocus={true}
					disabled={isLastRecordCollector}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="open chat screen"
								edge="end"
								color="primary"
								disabled={query.trim() === ""}
								onClick={handleSubmit}
							>
								<IoMdSend />
							</IconButton>
						</InputAdornment>
					}
					placeholder={sendMsgLocale && sendMsgLocale.name ? sendMsgLocale.name : "Ask me anything"}
				/>
			</FormControl>
			<Footer />
		</Box>

	);
};

export default memo(Chat);