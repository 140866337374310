import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Box,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import {
  Message,
  MessageAndButtons,
  Gallery,
  DataCollector,
  ContactDataCollector,
  Ticket,
} from "./../../components/widgets";
import { INTELSWIFT_GRAY_LOGO } from "../../constants";

export default function Reciever(props) {
  let ReplyContent = null;
  const { content, agents, isIncoming } = props;
  const { agentIDaasId, type, message } = content;
  const [hidden, setHidden] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [animate, setAnimate] = useState(false);

   // Simulate typing effect for incoming messages
  const typeMessage = (message) => {
    if (!message) return;
    setIsTyping(true);
    let index = 0;
    const interval = setInterval(() => {
      setCurrentMessage((prev) => {
        const nextMessage = message.slice(0, index + 1); // Slice the message up to the current index
        index++;
        if (index === message.length) {
          clearInterval(interval); // Clear the interval when done
          setIsTyping(false);
        }
        return nextMessage;
      });
    }, 50); 
  };

    // Render AI assistant info
  const getAssistant = (agentIDaasId) => {
    const suffix = "AI agent";
    const name = agentIDaasId.substring("ai_assistant_".length) + ` (${suffix})`;
    return (
      <Stack direction="row" spacing={1}>
        <Avatar className="agent-avatar">{INTELSWIFT_GRAY_LOGO}</Avatar>
        <Typography variant="body1">{name}</Typography>
      </Stack>
    );
  };

   // Render agent info
  const getAgent = (agentIDaasId) => {
    const agent = agents.find((el) => el.agentIDaasId === agentIDaasId);
    if (agent) {
      const { name } = agent;
      return (
        <Stack direction="row" spacing={1}>
          <Typography variant="body1">{name}</Typography>
        </Stack>
      );
    } else {
      return "";
    }
  };

   // Determine the content to render based on type
  switch (type) {
    case "msg_and_buttons":
      ReplyContent = <MessageAndButtons {...props} />;
      break;
    case "msg":
      ReplyContent = <Message content={{ ...content, message: isTyping ? currentMessage : message }} />;
      break;
    case "assistant":
      ReplyContent = <Message {...props} />;
      break;
    case "gallery":
      ReplyContent = <Gallery {...props} />;
      break;
    case "ticket":
      ReplyContent = <Ticket {...props} setHidden={setHidden} />;
      break;
    case "contact_data_collector":
      ReplyContent = (
        <ContactDataCollector {...props} setHidden={setHidden} />
      );
      break;
    case "data_collector":
      ReplyContent = <DataCollector {...props} setHidden={setHidden} />;
      break;
    default:
      console.warn(`Unrecognized type ${type} of receiver content.`);
  }

    // Handle typing effect or fallback message
  useEffect(() => {
    if (isIncoming && agentIDaasId?.includes("ai_assistant") && type === "msg") {
      if (message) typeMessage(message); // Only start typing if message is valid
    } else if (!isIncoming) {
      setCurrentMessage(message || ""); // Fallback to empty string for undefined messages
    }
  }, [isIncoming, agentIDaasId, type, message]);

  // Trigger animation when content changes
  useEffect(() => {
    if (content) {
      setAnimate(true);
      const timeout = setTimeout(() => setAnimate(false), 500); // Match animation duration
      return () => clearTimeout(timeout);
    }
  }, [content]);

  return (
    <>
      {content && !hidden && (
        <Stack className="reciever-ui" direction="column" spacing={1}>
          {/* Display agent or assistant information */}
          <Stack className="receiver-info">
            {agentIDaasId && agentIDaasId !== "assistant" && getAgent(agentIDaasId)}
            {agentIDaasId && agentIDaasId.includes("ai_assistant") && getAssistant(agentIDaasId)}
          </Stack>
          {/* Render message content */}
          <Box
            className={`reciever-message-container ${
              animate ? "animate-fade-in-left" : ""
            } ${type === "data_collector" || type === "ticket" || type === "gallery" ? "full-width" : "fit-content-width"
            }`}
          >
            {ReplyContent}
          </Box>
        </Stack>
      )}
    </>
  );
}