import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { IconButton, Box, Stack, List, ListItemButton, ListItemText, CardHeader, Typography } from "@mui/material";
import AvatarStack from "../../components/AvatarStack";
import { formatTimeAgo, trimString } from "../../utils/common";
import { Context } from "../../store/auth";
import Footer from "../../components/Footer";
import eventEmitter from "../../components/EventEmitter";
import notificationSound from "../../assets/sounds/notification.wav";

// Conversations component to display and navigate through conversation history
const Conversations = () => {
    // Extract state and functions from Context
    const { conversations, agents, setConversations, setPage } = useContext(Context);
    // State for storing all processed conversations
    const [allConv, setAllConv] = useState([]);
    const navigate = useNavigate();

    // Function to hide the widget by sending a message to the parent window
    const hideWidget = () => {
        window.parent.postMessage({ hideWidget: true }, "*");
    };

    // Utility function to format the "time ago" string
    const getTimeAgo = (timeStr) => {
        try {
            return formatTimeAgo(timeStr);
        } catch (error) {
            console.error("@@ Error: getTimeAgo @@", error);
            return null;
        }
    };

    // Function to update conversation records dynamically
    const updateConversationRecords = (record) => {
        setConversations((prevConversations) =>
            prevConversations.map((conversation) => {
                const updatedConversation = { ...conversation };
                // Assign a conversation ID to placeholder conversations
                if (updatedConversation.placeholder && !updatedConversation._id) {
                    updatedConversation._id = record.conversationId;
                }
                // Update records for matching conversations
                if (updatedConversation._id === record.conversationId) {
                    updatedConversation.records = mergeConversationRecords(
                        updatedConversation.records,
                        record
                    );
                }
                return updatedConversation;
            })
        );
    };

    // Merges a new record into an existing list of conversation records
    const mergeConversationRecords = (records, newRecord) => {
        let recordExists = false; // Flag to check if the record already exists in the list
        // Map through the existing records and either update or retain them
        const updatedRecords = records.map((record) => {
            // Check if the current record matches the new record by ID
            if (record._id === newRecord._id) {
                recordExists = true; // Mark the record as existing
                return newRecord; // Replace the existing record with the new one
            }
            return record; // Retain the existing record if no match is found
        });
        // If the new record doesn"t already exist, add it to the list
        if (!recordExists) {
            updatedRecords.push({ ...newRecord });
        }
        return updatedRecords; // Return the updated list of records
    };

    // Function to play a notification sound for new messages
    const playNotificationSound = () => {
        const audio = new Audio(notificationSound); // Use the imported sound file
        audio.play().catch((error) => {
            console.error("Error playing notification sound:", error); // Log any playback errors
        });
    };

    // Event handler for updating records
    const handleUpdateRecord = (message) => {
        try {
            const { contactId, record } = message;
            if (contactId) {
                playNotificationSound();
                updateConversationRecords(record);
            }
        } catch (error) {
            console.error("@@ Error: handleUpdateRecord @@", error);
        }
    };

    // Navigate back to the home page
    const handleBack = () => {
        setPage({ location: "home", props: {} });
        navigate("/private/home");
    };

    // Navigate to the Chat component for a specific conversation
    const handleNavigateToChat = (conversationId, assignedAgents) => {
        navigate("/private/chat", {
            state: {
                conversationId,
                assignedAgents,
                displayAgentInfo: true,
                origin: "conversations",
            },
        });
    };

    // Process conversations and agents to prepare data for display
    useEffect(() => {
        if (Array.isArray(conversations) && Array.isArray(agents)) {
            // Filter out placeholder conversations and sort by the most recent update
            const data = conversations
                .filter((conv) => conv.placeholder !== true)
                .sort((a, b) => {
                    const lastUpdatedA = new Date(
                        a.records[a.records.length - 1]?.updatedAt || a.createdAt
                    );
                    const lastUpdatedB = new Date(
                        b.records[b.records.length - 1]?.updatedAt || b.createdAt
                    );
                    return lastUpdatedB - lastUpdatedA; // Sort descending by the last update
                });
            // Map through filtered conversations to extract relevant details
            const allConversations = data.map((conv) => {
                const assignedAgents = [];
                const lastRecord = conv.records[conv.records.length - 1]; // Get the last record
                // Find and assign agents for the conversation
                conv.assignees.forEach((agentIDaasId) => {
                    const agent = agents.find((el) => el.agentIDaasId === agentIDaasId);
                    if (agent) {
                        assignedAgents.push(agent);
                    }
                });
                return {
                    conversationId: conv._id, // Conversation ID
                    assignees: conv.assignees, // List of assignees
                    agents: assignedAgents, // Assigned agents
                    lastMsg: trimString(
                        lastRecord?.reply?.message || lastRecord?.query?.message,
                        31 // Trim message to 31 characters
                    ),
                    time: getTimeAgo(lastRecord?.updatedAt || conv.createdAt), // Format time ago
                };
            });
            setAllConv(allConversations); // Update state with processed conversations
        }
    }, [conversations, agents]);

    // Attach event listeners for conversation updates
    useEffect(() => {
        eventEmitter.on("record_update", handleUpdateRecord);
        // Cleanup listener on unmount
        return () => {
            eventEmitter.off("record_update", handleUpdateRecord);
        };
    }, []);

    // Header component for the Conversations page
    const Header = () => {
        return (
            <CardHeader className="basic-header"
                avatar={(
                    <IconButton aria-label="back" onClick={handleBack}>
                        <IoChevronBack />
                    </IconButton>
                )}
                action={
                    <IconButton aria-label="close" onClick={hideWidget}>
                        <IoMdClose />
                    </IconButton>
                }
                title={(
                    <Typography variant="body1" className="basic-header-title">Conversations</Typography>
                )}
            />
        )
    }

    // Render the Conversations component layout
    return (
        <Box className="conversations-container">
            <Header />
            <Box className="conversations-body">
                {Array.isArray(allConv) && allConv.length > 0 && (
                    <List className="conversations-list">
                        {allConv.map((conv, idx) => (
                            <Stack key={`conversation-item-${idx}`}>
                                <ListItemButton
                                className="conversations-list-button"
                                    onClick={() => handleNavigateToChat(conv.conversationId, conv.agents)}
                                >
                                    <ListItemText>
                                        <Stack direction="row" spacing={1} className="conversations-stack">
                                            <AvatarStack agents={conv.agents || []} />
                                            <Stack direction="column" spacing={0}>
                                                <Typography variant="body1">
                                                    {conv.lastMsg}
                                                </Typography>
                                                <Stack direction="row" spacing={1}>
                                                    {conv.agents.length === 1 && (
                                                        <Typography
                                                            variant="body1"
                                                            className="conversation-assigned-agents-name"
                                                        >
                                                            {conv.agents[0].name}
                                                        </Typography>
                                                    )}
                                                    <Typography>•</Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="conversation-time"
                                                    >
                                                        {conv.time}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </ListItemText>
                                    <KeyboardArrowRightIcon />
                                </ListItemButton>
                            </Stack>
                        ))}
                    </List>
                )}
            </Box>
            <Footer />
        </Box>
    );
};

export default Conversations;