import React, { useEffect, useRef } from "react";
import {
	Box,
	Stack,
} from "@mui/material";
import Sender from "./../../components/Sender";
import Reciever from "./../../components/Reciever/Reciever";

// History component to display a list of messages in a chat conversation
const History = (props) => {
	// Ref to track the last message container for scrolling
	const messagesRef = useRef(null);
	// Destructure props to extract necessary data
	const { records, contact, conversationId } = props;
	// Function to scroll to the bottom of the chat history
	function scrollBottom(behavior = "initial") {
		const scrollObject = { block: "end" };
		if (behavior !== "initial") scrollObject.behavior = behavior;
		setTimeout(() => messagesRef.current?.scrollIntoView(scrollObject), 100);
	}

	// Effect to auto-scroll to the bottom whenever `records` change
	useEffect(() => {
		scrollBottom("initial");
	}, [records]);

	// Render the chat history
	return (
		<Stack spacing={0} className="chat-history">
			{records && records.map((record, idx) => {
				return (
					<Stack key={"history" + idx} direction="column">
						{/* Render Sender component for user queries */}
						{record.query &&
							<Sender
								content={record.query}
								contact={contact}
							/>
						}
						{/* Render Reciever component for AI or live agent replies */}
						{record.reply &&
							<Reciever
								{...props}
								content={record.reply}
								isIncoming={record.isIncoming || false}
								conversationId={conversationId}
							/>
						}
					</Stack>
				);
			})}
			<Box ref={messagesRef} className="messages-ref-container" />
		</Stack>
	);
};

export default History;